<template>
  <div>
    <navbar-top left="back"
                middle="title"
                @cl="back">
      <p class="text-2xl text-white font-semibold">Создать тест</p>
    </navbar-top>

    <div class="fixed inset-0 py-14 bg-blue-850 overflow-y-auto">
      <div v-if="dataLoaded"
           class="wrapper">
        <div class="flex flex-col mb-4 p-3 sm:px-10 text-white">
          <label for="themesContainer"
                 class="text-xl font-semibold">Темы:</label>
          <div id="themesContainer"
               class="mt-1 px-4 py-2 rounded-lg bg-blue-950 overflow-y-auto select-none"
               style="min-height: 20vh; max-height: 40vh;">

            <div v-for="subject in themesTree.root.children"
                 :key="subject"
                 class="py-1">
              <div class="flex items-center">
                <input v-model="themesTree[subject].checked"
                       @change="recalculateChecks(themesTree[subject])"
                       :id="subject"
                       type="checkbox"
                       :indeterminate.prop="themesTree[subject].indeterminate"
                       class="mr-2">
                <label :for="subject"
                       class="text-white font-bold">{{ humanizeSubject(themesTree[subject].name) }}</label>
              </div>
              <div v-for="section in themesTree[subject].children"
                   :key="section"
                   class="">
                <div class="flex items-center pl-4">
                  <input v-model="themesTree[section].checked"
                         @change="recalculateChecks(themesTree[section])"
                         :id="section"
                         type="checkbox"
                         :indeterminate.prop="themesTree[section].indeterminate"
                         class="mr-2">
                  <label :for="section"
                         class="text-white italic">{{ themesTree[section].name }}</label>
                </div>
                <div v-for="chapter in themesTree[section].children"
                     :key="chapter">
                  <div class="flex items-center pl-8">
                    <input v-model="themesTree[chapter].checked"
                           @change="recalculateChecks(themesTree[chapter])"
                           :id="chapter"
                           :indeterminate.prop="themesTree[chapter].indeterminate"
                           type="checkbox"
                           class="mr-2">
                    <label :for="chapter"
                           class="text-white">{{ themesTree[chapter].name }}</label>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <label for="mode"
                 class="mt-6 text-xl font-semibold">Режим:</label>
          <div id="mode"
               class="mode-wrapper">
            <div :class="mode === 'n' ? ' bg-blue-600' : ''"
                 class="mode-choice"
                 @click="setMode('n')">
              <font-awesome-icon icon="random"
                                 class="text-white text-2xl"></font-awesome-icon>
              <p class="inline ml-2">Случайные ({{ checkedData.count }})</p>
            </div>
            <div :class="mode === 'currentIncorrect' ? ' bg-blue-600' : ''"
                 class="mode-choice"
                 @click="setMode('currentIncorrect')">
              <font-awesome-icon icon="bomb"
                                 class="text-white text-2xl"></font-awesome-icon>
              <p class="inline ml-2">Незакрытые ошибки ({{ checkedData.currentIncorrect }})</p>
            </div>
            <div :class="mode === 'anyIncorrect' ? ' bg-blue-600' : ''"
                 class="mode-choice"
                 @click="setMode('anyIncorrect')">
              <font-awesome-icon icon="exclamation"
                                 class="text-white text-2xl mr-1"></font-awesome-icon>
              <p class="inline ml-2">Все ошибки ({{ checkedData.anyIncorrect }})</p>
            </div>
            <div :class="mode === 'flagged' ? ' bg-blue-600' : ''"
                 class="mode-choice"
                 @click="setMode('flagged')">
              <font-awesome-icon icon="flag"
                                 class="text-white text-2xl mr-1"></font-awesome-icon>
              <p class="inline ml-2">Флажки ({{ checkedData.flagged }})</p>
            </div>
          </div>

          <label for="size"
                 class="mt-6 text-xl font-semibold">Количество:</label>
          <div id="size"
               class="flex justify-between text-center align-center mt-1 rounded-lg bg-blue-950 cursor-pointer">
            <div :class="size === '20' ? ' bg-blue-600' : ''"
                 class="py-4 w-full rounded-lg"
                 @click="setSize('20')">20</div>
            <div :class="size === '40' ? ' bg-blue-600' : ''"
                 class="py-4 w-full rounded-lg"
                 @click="setSize('40')">40</div>
            <div :class="size === 'all' ? ' bg-blue-600' : ''"
                 class="py-4 w-full rounded-lg"
                 @click="setSize('all')">Все</div>
          </div>
        </div>
      </div>
      <menu-spinner v-else></menu-spinner>
    </div>

    <navbar-bottom state="launching"
                   :data="{ count: expectedCount }"
                   @start="start" />
  </div>
</template>

<script>
import axiosInstance from '@/services/axios';
import NavbarTop from '@/components/NavbarTop';
import NavbarBottom from '@/components/NavbarBottom';
import MenuSpinner from '@/components/MenuSpinner';

function Node (location, parent, count, flagged, anyIncorrect, currentIncorrect) {
  this.name = location.split('###').pop();
  this.parent = parent;
  this.children = [];
  this.checked = false;
  this.indeterminate = false;
  this.count = count;
  this.flagged = flagged;
  this.anyIncorrect = anyIncorrect;
  this.currentIncorrect = currentIncorrect;

  this.incrementCounts = function (count, flagged, anyIncorrect, currentIncorrect) {
    this.count += count;
    this.flagged += flagged;
    this.anyIncorrect += anyIncorrect;
    this.currentIncorrect += currentIncorrect;
  };

  this.signalChange = function () {
    console.log(JSON.stringify(this, null, 3));
    return true;
  }
}

function buildCheckTree (themes) {
  const HT = { root: new Node('root', null, 0, 0, 0) };

  for (const theme of themes) {
    let parentLocation = 'root';
    let currentLocation = '';
    const locationArray = theme.location.split('###');

    while (locationArray.length > 0) {
      currentLocation += '###' + locationArray.shift();
      currentLocation = currentLocation.replace(/^###/g, '');

      if (HT.hasOwnProperty(currentLocation))
        HT[currentLocation].incrementCounts(theme.count, theme.flagged, theme.anyIncorrect, theme.currentIncorrect);
      else {
        HT[currentLocation] = new Node(currentLocation, parentLocation, theme.count, theme.flagged, theme.anyIncorrect, theme.currentIncorrect);
        HT[parentLocation].children.push(currentLocation);
      }

      parentLocation = currentLocation;
    }
  }

  return HT;
}

export default {
  props: {
    subject: String,
  },
  components: { NavbarTop, NavbarBottom, MenuSpinner },
  data() {
    return {
      themesTree: {},
      mode: 'n',
      size: '20',
      dataLoaded: false,
    };
  },
  computed: {
    checkedData () {
      let data = Object.entries(this.themesTree).filter((e) => {
        return e[1].checked && !e[1].indeterminate && e[1].children.length === 0;
      });

      if (data.length === 0)
        data = Object.entries(this.themesTree).filter((e) => e[1].children.length === 0);

      return data.reduce((a,c) => {
        a.locations.push(c[0]);
        a.count += c[1].count;
        a.flagged += c[1].flagged;
        a.anyIncorrect += c[1].anyIncorrect;
        a.currentIncorrect += c[1].currentIncorrect;
        return a;
      }, { locations: [], count: 0, flagged: 0, anyIncorrect: 0, currentIncorrect: 0 });
    },
    expectedCount () {
      const maxSize = !Number.isNaN(parseInt(this.size, 10)) ? parseInt(this.size, 10) : 10000;
      if (this.mode === 'n') return Math.min(this.checkedData.count, maxSize);
      if (this.mode === 'flagged') return Math.min(this.checkedData.flagged, maxSize);
      if (this.mode === 'anyIncorrect') return Math.min(this.checkedData.anyIncorrect, maxSize);
      if (this.mode === 'currentIncorrect') return Math.min(this.checkedData.currentIncorrect, maxSize);
      return 0;
    },
  },
  created () {
    if (!this.subject) this.$router.push({ name: 'PageDashboard' });
    const url = `/api/v3/ege/locations?subject=${this.subject}`;
    axiosInstance.get(url)
      .then((result) => {
        const sortedThemes = result.data.sort((a,b) => a.location.localeCompare(b.location, undefined, { numeric: true }));
        this.themesTree = buildCheckTree(sortedThemes);
        this.dataLoaded = true;
      });
  },
  methods: {
    humanizeSubject (s) {
      if (s === 'bio') return 'Биология';
      if (s === 'chem') return 'Химия';
      return '';
    },
    propelDownwards (node) {
      if (node.children.length === 0) return true;

      for (const child of node.children) {
        this.themesTree[child].checked = node.checked;
        this.themesTree[child].indeterminate = false;
        this.propelDownwards(this.themesTree[child]);
      }
    },
    propelUpwards (node) {
      if (!node.parent) return true;

      const TT = this.themesTree;
      const parentNode = TT[node.parent];

      const allChildrenChecked = parentNode.children.every(c => TT[c].checked);
      const someChildrenChecked = parentNode.children.some(c => TT[c].checked || TT[c].indeterminate);

      if (allChildrenChecked) {
        parentNode.checked = true;
        parentNode.indeterminate = false;
      } else if (someChildrenChecked) {
        parentNode.checked = false;
        parentNode.indeterminate = true;
      } else {
        parentNode.checked = false;
        parentNode.indeterminate = false;
      }

      this.propelUpwards(parentNode);
    },
    recalculateChecks (node) {
      node.indeterminate = false;
      this.propelDownwards(node);
      this.propelUpwards(node);

      return true;
    },
    setMode (m) { this.mode = m; },
    setSize (s) { this.size = s; },
    start () {
      if (this.expectedCount < 1) return false;
      const params = {
        mode: this.mode,
        locations: this.checkedData.locations,
        size: this.size,
      };
      this.$router.push({ name: 'PageEge', params });
    },
    back () { this.$router.push('/'); },
  },
}
</script>

<style scoped lang="scss">
  input[type=checkbox] {  flex: 1;  }
  label {
    @media (max-width: 639px) {
      flex: 20;
    }

    @media (min-width: 640px) {
      flex: 36;
    }
  }

  .wrapper {
    display: block;
    margin: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media (max-width: 639px) {
      width: 100%;
    }

    @media (min-width: 640px) {
      width: 75%;
    }

    @media (min-width: 1024px) {
      width: 50%;
    }
  }

  .mode-wrapper {
    @apply flex flex-col md:flex-row md:flex-wrap justify-between text-center mt-1 rounded-lg bg-blue-950 cursor-pointer;
  }

  .mode-choice {
    @apply flex justify-center py-4 w-full rounded-lg;

    @media (min-width: 640px) {
      flex-basis: 50%;
    }
  }
</style>
